import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export default function useI18Next() {
	const { fallback: fallbackLng, supported: supportedLngs } = SITE_LANGUAGES;

	return i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			fallbackLng,
			supportedLngs: Object.keys(supportedLngs),
			debug: process.env.GATSBY_ACTIVE_ENV === "development",
			interpolation: {
				escapeValue: false,
			},
			react: {
				useSuspense: false,
			},
			detection: {
				order: ["path", "navigator"],
			},
		});
}

export function useSiteLanguagesData() {
	return SITE_LANGUAGES;
}
