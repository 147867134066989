// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-case-study-jsx": () => import("./../../../src/templates/CaseStudy.jsx" /* webpackChunkName: "component---src-templates-case-study-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-redirect-jsx": () => import("./../../../src/templates/Redirect.jsx" /* webpackChunkName: "component---src-templates-redirect-jsx" */),
  "component---src-templates-standard-jsx": () => import("./../../../src/templates/Standard.jsx" /* webpackChunkName: "component---src-templates-standard-jsx" */)
}

